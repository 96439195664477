import styled, { css } from 'styled-components';

export type ButtonProps = {
    size?: "big" | "small";
    type: "blue" | "white";
}

const Button = styled.div<ButtonProps>`
   border-radius: 8px;
   transition: 0.2s ease-in-out;
   font-family: Museo;
   font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-width: 184px;
    min-height: 51px;
    text-decoration: none;
    font-weight: 400;
    overflow: hidden;
    position: relative;
    z-index: 1;
    cursor: pointer;
    
   ${({ type }) =>
        type === "blue" &&
        css`
        color: ${({ theme }) => theme.whiteColor};
        background-color: ${({ theme }) => theme.blueColor};

    `}

   ${({ type }) =>
        type === "white" &&
        css`
        color: ${({ theme }) => theme.blueColor};
        background-color: ${({ theme }) => theme.whiteColor};
    `}

   :focus {
       outline: none;
   }
   ::before {
        content: '';
        z-index: -1;
        position: absolute;
        top: 50%;
        left: 100%;
        margin: -15px 0 0 1px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: ${({ theme }) => theme.blueHoverColor}; 
        -webkit-transform-origin: 100% 50%;
        transform-origin: 100% 50%;
        -webkit-transform: scale3d(1, 2, 1);
        transform: scale3d(1, 2, 1);
        -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
        transition: transform 0.3s, opacity 0.3s;
        -webkit-transition-timing-function: cubic-bezier(0.7,0,0.9,1);
        transition-timing-function: cubic-bezier(0.7,0,0.9,1);
   }

   :hover::before {
        -webkit-transform: scale3d(15, 9, 1);
        transform: scale3d(15, 9, 1);

        @media (min-width: 1px) and (max-width: 450px) {
            transform: none;
        }
    }

   @media (min-width: 768px) and (max-width: 991.98px) {
        width: 125px;
    }
    @media (min-width: 992px) and (max-width: 1600px) {
        width: 162px;
    }
    @media (max-width: 767.98px) {
        width: 125px;
    } 
`;


export default Button;
