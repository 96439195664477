import * as React from 'react';
import styled from 'styled-components';

type PupUpContainerProps = {
    method: any;
}

const StyledPopUpContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
`

const PupUpBackground = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: ${({ theme }) => theme.popUpBackgroundColor};
`

const PopUpContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${({ theme }) => theme.whiteColor};
    padding: 55px 60px;
    background: linear-gradient(1.25deg, #F5F5F5 0.95%, #FFFFFF 98.8%);
    border: 2px solid ${({ theme }) => theme.primaryColor};
    box-shadow: ${({ theme }) => theme.boxShadow}; 
    border-radius: 16px;
    position: relative;
    width: 515px;
    z-index: 1500;

    .contactPopup__image {
        margin-bottom: 25px;
        height: 190px;
        width: 190px;
        border-radius: 50%;
    }
    
    @media (min-width: 768px) and (max-width: 991.98px) {
        width: 400px;
        padding: 30px 35px;
        .contactPopup__image {
            height: 150px;
            width: 150px;
        }
    }

    @media (min-width: 1200px) and (max-width: 1600px) {
        padding: 30px 35px;
        .contactPopup__image {
            height: 150px;
            width: 150px;
        }
    }

    @media (max-width: 767.98px) {
        width: calc(100% - 32px);
        max-width: 400px;
        padding: 25px 30px;
        .contactPopup__image {
            width: 130px;
            height: 130px;
        }
    }  
`

 
export const PopUpContainer: React.FC<PupUpContainerProps> = ({children, method}) => {
    return ( 
        <StyledPopUpContainer>
            <PupUpBackground onClick={method}/>
            <PopUpContent>
                {children}
            </PopUpContent>
        </StyledPopUpContainer>
     );
}