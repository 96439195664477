import * as React from 'react';
import styled from 'styled-components';
import exitButton from '../../images/button-exit-blue.svg';

type PupUpHeaderProps = {
    method: any;
    title: string;
}

const StyledPupUpHeader = styled.div`
    display: flex;
    width: 100%;

    @media (max-width: 767.98px) {
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }  
`;

const ExitButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    
    :focus {
        outline: none;
    }

    @media (min-width: 767.98px) {
        display: none;
    }  
`;

const PopUpTitle = styled.h3`
    margin: 0;
    margin-bottom: 25px;
    
    :first-letter {
        text-transform: uppercase
    }
        
    @media (min-width: 768px) and (max-width: 1600px) {
        margin-bottom: 16px;
    }

    @media (max-width: 767.98px) {
        font-size: 20px;
        line-height: 140%;
        margin-bottom: 0;
    }
`

const StyledImage = styled.img`
    display: block;
    width: 35px;
    height: 20px;
`;


 
export const PupUpHeader: React.FC<PupUpHeaderProps> = ({method, title}) => {
    return ( 
        <StyledPupUpHeader>
            <PopUpTitle>{title}</PopUpTitle>
            <ExitButton onClick={method}>
                <StyledImage src={exitButton} alt="krzyżyk do zamykania"/>
            </ExitButton>
        </StyledPupUpHeader>
     );
}