import * as React from 'react';
import styled from 'styled-components';
import IconX from '../../images/icon-x.svg'

type PupUpButtonExitProps = {
    method: any;
}

const StyledPopUpButtonExit = styled.button`
    position: absolute;
    width: 34px;
    height: 34px;
    right: -44px;
    border-radius: 50%;
    top: 0px;
    border: none;
    background-color: ${({ theme }) => theme.blueColor};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    :hover {
        background-color: ${({ theme }) => theme.blueHoverColor};
    }

    @media (max-width: 767.98px) {
        display: none;
    }  
`;

const ButtonExitIcon = styled.img`
`;

 
export const PopUpButtonExit: React.FC<PupUpButtonExitProps> = ({method}) => {
    return ( 
        <StyledPopUpButtonExit onClick={method}>
            <ButtonExitIcon src={IconX} alt="ikona wyjście"/>
        </StyledPopUpButtonExit>
     );
}